import React from "react";
import "./rentability.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styled from "styled-components";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
/*
function RentabilityTable20() {
  const table20 = [
    {
      Ano: "2002",
      Ibovespa: "-17%",
      Ouro: "71.37%",
      CDI: "19%",
      Poupança: "9.27%",
      Dólar: "43.46%",
    },
    {
      Ano: "2003",
      Ibovespa: "97.3%",
      Ouro: "-3.66%",
      CDI: "23.17%",
      Poupança: "11.1%",
      Dólar: "-20.57%",
    },
    {
      Ano: "2004",
      Ibovespa: "17.8%",
      Ouro: "-8.11%",
      CDI: "16.1%",
      Poupança: "8.12%",
      Dólar: "-2.84%",
    },
    {
      Ano: "2005",
      Ibovespa: "27.7%",
      Ouro: "2.93%",
      CDI: "19%",
      Poupança: "9.18%",
      Dólar: "-11.82%",
    },
    {
      Ano: "2006",
      Ibovespa: "32.9%",
      Ouro: "12.69%",
      CDI: "15.03%",
      Poupança: "8.23%",
      Dólar: "-8.66%",
    },
    {
      Ano: "2007",
      Ibovespa: "43.7%",
      Ouro: "11.27%",
      CDI: "11.77%",
      Poupança: "7.63%",
      Dólar: "-17.15%",
    },
    {
      Ano: "2008",
      Ibovespa: "-41.2%",
      Ouro: "32.13%",
      CDI: "12.32%",
      Poupança: "7.88%",
      Dólar: "31.94%",
    },
    {
      Ano: "2009",
      Ibovespa: "82.7%",
      Ouro: "-3.05%",
      CDI: "9.84%",
      Poupança: "6.92%",
      Dólar: "-25.49%",
    },
    {
      Ano: "2010",
      Ibovespa: "1%",
      Ouro: "32.26%",
      CDI: "9.71%",
      Poupança: "6.9%",
      Dólar: "-4.31%",
    },
    {
      Ano: "2011",
      Ibovespa: "-18.1%",
      Ouro: "15.9%",
      CDI: "11.6%",
      Poupança: "7.5%",
      Dólar: "13.2%",
    },
    {
      Ano: "2012",
      Ibovespa: "7.4%",
      Ouro: "15.26%",
      CDI: "8.37%",
      Poupança: "6.58%",
      Dólar: "8.94%",
    },
    {
      Ano: "2013",
      Ibovespa: "-15.5%",
      Ouro: "-17.35%",
      CDI: "8.02%",
      Poupança: "6.34%",
      Dólar: "14.64%",
    },
    {
      Ano: "2014",
      Ibovespa: "-2.9%",
      Ouro: "12.04%",
      CDI: "10.77%",
      Poupança: "6.43%",
      Dólar: "13.39%",
    },
    {
      Ano: "2015",
      Ibovespa: "-13.3%",
      Ouro: "32.15%",
      CDI: "13.24%",
      Poupança: "7.29%",
      Dólar: "48.49%",
    },
    {
      Ano: "2016",
      Ibovespa: "38.9%",
      Ouro: "-12.32%",
      CDI: "14%",
      Poupança: "7.57%",
      Dólar: "-17.6%",
    },
    {
      Ano: "2017",
      Ibovespa: "26.9%",
      Ouro: "15.97%",
      CDI: "9.93%",
      Poupança: "6.61%",
      Dólar: "1.99%",
    },
    {
      Ano: "2018",
      Ibovespa: "15%",
      Ouro: "16.93%",
      CDI: "6.42%",
      Poupança: "4.62%",
      Dólar: "16.92%",
    },
    {
      Ano: "2019",
      Ibovespa: "31.6%",
      Ouro: "28.3%",
      CDI: "5.96%",
      Poupança: "4.3%",
      Dólar: "3.6%",
    },
    {
      Ano: "2020",
      Ibovespa: "2.92%",
      Ouro: "49.19%",
      CDI: "2.75%",
      Poupança: "2.11%",
      Dólar: "29.46%",
    },
    {
      Ano: "2021",
      Ibovespa: "-11.93%",
      Ouro: "4.43%",
      CDI: "4.35%",
      Poupança: "3.05%",
      Dólar: "7.39%",
    },
  ];
  // table20.sort((a, b) => b.Ano - a.Ano);
  return (
    <div className="overflow-auto">
      <table className="table table-hover table-bordered cTable mb-0">
        <thead>
          <tr>
            <th scope="col fw-bold text-white">Ano</th>
            <th scope="col fw-bold">Dólar</th>
            <th scope="col fw-bold">Poupança</th>
            <th scope="col fw-bold">CDI</th>
            <th scope="col fw-bold">Ibovespa</th>
            <th scope="col fw-bold">Ouro</th>
          </tr>
        </thead>
        <tbody>
          {table20.map((item, i) => (
            <tr key={i++}>
              <td>{item.Ano}</td>
              <td>{item.Dólar}</td>
              <td>{item.Poupança}</td>
              <td>{item.CDI}</td>
              <td>{item.Ibovespa}</td>
              <td>{item.Ouro}</td>
            </tr>
          ))}
        </tbody>

        <tfoot className=" fw-bold" style={{ background: "#D8D8D8" }}>
          <tr>
            <td>Total 20 anos</td>
            <td>124,98%</td>
            <td>137,63%</td>
            <td>231,35% </td>
            <td>305,89%</td>
            <td>308,33%</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}*/

function RentabilityTable10() {
  const table10 = [
    {
      Ano: "2013",
      Ibovespa: "-15.50%",
      Ouro: "-17.35%",
      CDI: "8.02%",
      Poupança: "6.34%",
      Dólar: "14.64%",
    },
    {
      Ano: "2014",
      Ibovespa: "-2.90%",
      Ouro: "12.04%",
      CDI: "10.77%",
      Poupança: "6.43%",
      Dólar: "13.39%",
    },
    {
      Ano: "2015",
      Ibovespa: "-13.30%",
      Ouro: "32.15%",
      CDI: "13.24%",
      Poupança: "7.29%",
      Dólar: "48.49%",
    },
    {
      Ano: "2016",
      Ibovespa: "38.90%",
      Ouro: "-12.32%",
      CDI: "14.00%",
      Poupança: "7.57%",
      Dólar: "-17.60%",
    },
    {
      Ano: "2017",
      Ibovespa: "26.90%",
      Ouro: "15.97%",
      CDI: "9.93%",
      Poupança: "6.61%",
      Dólar: "1.99%",
    },
    {
      Ano: "2018",
      Ibovespa: "15.00%",
      Ouro: "16.93%",
      CDI: "6.42%",
      Poupança: "4.62%",
      Dólar: "16.92%",
    },
    {
      Ano: "2019",
      Ibovespa: "31.60%",
      Ouro: "28.30%",
      CDI: "5.96%",
      Poupança: "4.30%",
      Dólar: "3.60%",
    },
    {
      Ano: "2020",
      Ibovespa: "2.92%",
      Ouro: "49.19%",
      CDI: "2.75%",
      Poupança: "2.11%",
      Dólar: "29.46%",
    },
    {
      Ano: "2021",
      Ibovespa: "-11.93%",
      Ouro: "4.43%",
      CDI: "4.35%",
      Poupança: "3.05%",
      Dólar: "7.39%",
    },
    {
      Ano: "2022",
      Ibovespa: "-4.69%",
      Ouro: "-0.19%",
      CDI: "13.65%",
      Poupança: "7.14%",
      Dólar: "-5.12%",
    },
    {
      Ano: "2023",
      Ibovespa: "22.28%",
      Ouro: "12.46%",
      CDI: "13.04%",
      Poupança: "7.42%",
      Dólar: "-8.21%",
    },
    {
      Ano: "2024",
      Ibovespa: "14,64%",
      Ouro: "37,86%",
      CDI: "10,97%",
      Poupança: "7,03%",
      Dólar: "14,93%",
    },
  ];
  //table10.sort((a, b) => b.Ano - a.Ano);
  return (
    <div className="overflow-auto">
      <table className="table table-hover table-bordered cTable mb-0">
        <thead>
          <tr>
            <th scope="col fw-bold text-white bg-success">Ano</th>
            <th scope="col fw-bold">Poupança</th>
            <th scope="col fw-bold">Ibovespa</th>
            <th scope="col fw-bold">CDI</th>
            <th scope="col fw-bold">Dólar</th>
            <th scope="col fw-bold">Ouro</th>
          </tr>
        </thead>
        <tbody>
          {table10.map((item, i) => (
            <tr key={i++}>
              <td>{item.Ano}</td>
              <td>{item.Poupança}</td>
              <td>{item.Ibovespa}</td>
              <td>{item.CDI}</td>
              <td>{item.Dólar}</td>
              <td>{item.Ouro}</td>
            </tr>
          ))}
        </tbody>
        <tfoot className=" fw-bold" style={{ background: "#D8D8D8" }}>
          <tr>
            <td>Total 10 anos</td>
            <td>69,91%</td>
            <td>103,92%</td>
            <td>113,10%</td>
            <td>119,88%</td>
            <td>179,47%</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default function Rentabilitys() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div id="rentability" className="mt-5">
      <div className="Alert-cont-h p-0">
        <h3>TABELA DE RENTABILIDADE</h3>
      </div>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "#F7F7F7",
          border: "1px dotted grey",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            className="tabRent"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              className=" col-12 px-2 fw-bold"
              label="Aplicações financeiras nos últimos 10 anos"
              {...a11yProps(0)}
            />
            {/* <Tab
            className="col-6 p-0"
            label="Aplicações financeiras nos últimos 20 anos"
            {...a11yProps(1)}
          /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <RentabilityTable10 />
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
        <RentabilityTable20 />
      </TabPanel> */}
      
      </Box>
      <Legend>*Rentabilidade passada não representa rentabilidade futura.

</Legend>
    </div>
  );
}
const Legend = styled.p`
  font-size: 14px;
  text-align: start;
  color: #010101;
`;
