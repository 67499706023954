import React from 'react';
import './politicas.css';
import img from '../../../assets/newImages/politicaSocioAmbiental1.png';

export default function PoliticaSocial() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0  sectionInit ">
        <div className="c-init  ">
          <div className="c-init__text col-6  d-flex flex-column sect">
            <p className="fw-bolder text-decoration-underline">
              POLÍTICA DE RESPONSABILIDADE SOCIOAMBIENTAL ("PRSAC")
            </p>
            <p>
              Política elaborada por Marco Dulgheroff Novais (Jurídico),
              autorizada a publicação pelo Diretor de Compliance Elson do
              Nascimento Nunes Gusmão, destinada ao gerenciamento e prevenção de
              riscos socioambientais no âmbito da operação da{' '}
              <strong>OM DTVM</strong>.
            </p>
          </div>
          <div className="col-6 c-init__images">
            <img src={img} className="images" alt="politica-privacidade"></img>
          </div>
        </div>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                CONFORMIDADE E AMBITO DE APLICAÇÃO
              </li>
              <li className="list-unstyled">
                <p>
                  Esta Política está em conformidade com a Resolução BCB nº
                  4.954/21, uma vez que dispõe sobre as diretrizes a serem
                  aplicadas por esta <strong>OM DTVM</strong> no âmbito de sua
                  Responsabilidade Socioambiental.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                VALIDADE E DISPONIBILIZAÇÃO
              </li>
              <li className="list-unstyled">
                <p>
                  O conteúdo desta política é de responsabilidade do Diretor
                  Elson do Nascimento Nunes Gusmão da <strong>OM DTVM</strong>,
                  sendo este responsável pelo seu cumprimento. A gestão desta
                  Política (elaboração, conformidade, guarda, divulgação,
                  controle de atualizações e adesão) faz parte do Programa de
                  Compliance da <strong>OM DTVM</strong>.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Esta Política de Responsabilidade Socioambiental é reavaliada
                  e republicada a cada 2 (dois) anos, quando houver alterações
                  na <strong>OM DTVM</strong> ou em razão de requerimentos
                  legais e/ou administrativos que justifiquem a sua atualização
                  antecipada. A sua existência, assim como suas novas versões,
                  são aprovadas e disponibilizadas na rede pública interna
                  (Intranet ou Teams) e/ou sítio da <strong>OM DTVM</strong>.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  A <strong>OM DTVM</strong> oferece somente serviços permitidos
                  às Distribuidoras de Títulos e Valores Mobiliários e, no
                  evento de vir a oferecer demais produtos e serviços
                  suscetíveis a riscos socioambientais, esta Política de
                  Responsabilidade Socioambiental (“PRSAC”) também será
                  antecipadamente atualizada.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                OBJETIVO
              </li>
              <li className="list-unstyled">
                <p>
                  Divulgar os princípios e diretrizes que norteiam as ações de
                  Política de Responsabilidade Socioambiental (“<u>PRSAC</u>”)
                  desta <strong>OM DTVM</strong>, a qual, em consonância ao
                  Segmento (S4) da regulamentação prudencial do Banco Central do
                  Brasil (Supervisor), abarcada pelo Conselho Monetário Nacional
                  (Órgão Normativo), engendra e viabiliza a implementação de
                  requisitos objetivos para gerenciamento e prevenção de riscos
                  pelas Instituições Financeiras.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  A <strong>OM DTVM</strong> promove serviços nos setores de (i)
                  compra e venda de moeda estrangeira e (ii) compra de ouro como
                  ativo financeiro, sendo esta Política necessária para evitar
                  sua exposição à eventuais riscos socioambientais, sobre os
                  quais está sujeita ante a natureza complexa e ampla de suas
                  operações.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                PRINCÍPIOS QUE LASTREIAM A ATUAÇÃO DA OM DTVM
              </li>
              <li className="list-unstyled">
                <p>
                  A <strong>OM DTVM</strong> adota como princípio basilar a
                  condução de sua operação de forma consistente aos mais
                  elevados padrões éticos e em conformidade com todas as leis e
                  regulamentos aplicáveis e vigentes, bem como o repúdio a
                  qualquer ação que possa gerar danos socioambientais.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Nessa toada, a sua atuação apresenta profunda consonância aos
                  princípios de relevância e proporcionalidade, insculpidos no
                  art. 3º, da Resolução nº 4.954/21:
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  “Art. 3º Para fins desta Resolução, a PRSAC consiste no
                  conjunto de princípios e diretrizes de natureza social, de
                  natureza ambiental e de natureza climática a ser observado
                  pela instituição na condução dos seus negócios, das suas
                  atividades e dos seus processos, bem como na sua relação com
                  as partes interessadas.
                </p>
                <p>§ 1º Para fins desta Resolução, considera-se:</p>
                <li className="list-unstyled">
                  <p>
                    <ul>
                      <li className="list-unstyled">
                        I - natureza social, o respeito, a proteção e a promoção
                        de direitos e garantias fundamentais e de interesse
                        comum;
                      </li>
                      <br />
                      <li className="list-unstyled">
                        II - interesse comum, interesse associado a grupo de
                        pessoas ligadas jurídica ou factualmente pela mesma
                        causa ou circunstância, quando não relacionada à
                        natureza ambiental ou à natureza climática;
                      </li>
                      <br />
                      <li className="list-unstyled">
                        III - natureza ambiental, a preservação e a reparação do
                        meio ambiente, incluindo sua recuperação, quando
                        possível;
                      </li>
                      <br />
                      <li className="list-unstyled">
                        IV- natureza climática, a contribuição positiva da
                        instituição:
                        <p>
                          a) na transição para uma economia de baixo carbono, em
                          que a emissão de gases do efeito estufa é reduzida ou
                          compensada e os mecanismos naturais de captura desses
                          gases são preservados; e
                        </p>
                        <p>
                          b) na redução dos impactos ocasionados por intempéries
                          frequentes e severas ou por alterações ambientais de
                          longo prazo, que possam ser associadas a mudanças em
                          padrões climáticos; e{' '}
                        </p>
                      </li>
                      <li className="list-unstyled">
                        V- partes interessadas:
                        <p>
                          a) os clientes e usuários dos produtos e serviços da
                          instituição;
                        </p>
                        <p>b) a comunidade interna à instituição;</p>
                        <p>
                          c) os fornecedores e os prestadores de serviços
                          terceirizados relevantes da instituição;{' '}
                        </p>
                        <p>
                          d) os investidores em tulos ou valores mobiliários
                          emitidos pela instituição; e{' '}
                        </p>
                        <p>
                          e) as demais pessoas impactadas pelos produtos,
                          serviços, atividades e processos da instituição,
                          segundo critérios por ela definidos.”{' '}
                        </p>
                      </li>
                    </ul>
                  </p>
                </li>
              </li>
              <li className="list-unstyled">
                <p>
                  Em assim sendo, como explorar-se-á adiante, esta{' '}
                  <strong>OM DTVM</strong>, utilizará dos princípios da
                  relevância e proporcionalidade como norteadores da
                  viabilização e desenvolvimento de sua operação, uma vez que
                  funda sua operação em uníssona sustentabilidade.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Nessa esteira, sobre a utilização do{' '}
                  <u>princípio da relevância</u>, como mencionado alhures, um
                  dos ramos de atuação desta <strong>OM DTVM</strong> é a compra
                  de ouro como ativo financeiro, com o escopo de sua revenda
                  posterior.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Esta <strong>OM DTVM</strong>, na qualidade de Instituição
                  Financeira, exara sua ciência que tal operação está exposta à
                  riscos socioambientais, como a aquisição de ouro oriundo de
                  garimpo ilegal<sup>1</sup> ou a utilização de recursos por
                  esta dispendidos como um meio de financiamento de atividades
                  ilícitas.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Em razão disso e em atenção ao{' '}
                  <u>princípio da proporcionalidade</u>, esta{' '}
                  <strong>OM DTVM</strong> desenvolveu a presente Política de
                  Responsabilidade Socioambiental (“<u>PRSAC</u>”),
                  especificamente como um meio de salvaguardar que de sua
                  operação não decorram prejuízos socioambientais sensíveis, o
                  que será possível através da implementação de ações conjuntas
                  e ordenadas<sup>2</sup> , sempre em consonância aos
                  regulamentos do Banco Central e demais legislações aplicáveis.
                </p>
              </li>
            </ul>
            <span
              style={{
                fontSize: '0.8em',
                textAlign: 'left',
                marginTop: '20px',
              }}
            >
              <p>
                <sup>1</sup>Constituído pelo ouro oriundo do garimpo feito em
                áreas indígenas ou de proteção ambiental.
              </p>
              <p>
                <sup>2</sup>A título de exemplo, o que posteriormente será
                melhor analisado, esta OM DTVM reforça que não permite que seja
                associada a atos que causem danos socioambientais, bem como que
                suas operações sejam utilizadas para viabilizar e facilitar atos
                que causem danos socioambientais, o que tão somente é possível
                através da implementação de mecanismos de controle para
                diagnosticar e minimizar perdas referentes ao relacionamento com
                as partes interessadas e, também, respeita os direitos humanos e
                adota políticas compatíveis com a natureza, a dimensão e as
                características de suas operações, avaliando adequadamente o
                custo envolvido e sua aplicabilidade.
              </p>
            </span>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                ESTRUTURA DE GOVERNANÇA
              </li>
              <li className="list-unstyled">
                <p>
                  O art. 5º, da Resolução BCB nº 4.945/21, determina que as
                  Instituições Financeiras devem manter estrutura de governança
                  compatível com o seu porte, natureza do seu negócio,
                  complexidade de serviços e produtos oferecidos, bem como com
                  as atividades, processos e sistemas adotados, para assegurar o
                  cumprimento das diretrizes e dos objetivos da Política de
                  Responsabilidade Socioambiental (“<u>PRSAC</u>”).
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Nesse diapasão e em consonância ao princípio da
                  proporcionalidade, esta <strong>OM DTVM</strong> possui uma
                  estrutura de governança capaz de assegurar o cumprimento dos
                  Princípios e Diretrizes desta Política de Responsabilidade
                  Socioambiental (“<u>PRSAC</u>”), composta por:
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>
                      <strong>DIRETORIA:</strong> Responsável por aprovar as
                      diretrizes da Política de Responsabilidade Socioambiental
                      (“<u>PRSAC</u>”) e suas respectivas alterações, zelando
                      pela devida implementação dos procedimentos e controles
                      regulamentados pela legislação em vigor e, assegurar a
                      adoção de melhorias corretivas e preventivas para garantir
                      a efetividade do presente Manual.
                    </li>
                    <li>
                      <strong>COMPLIANCE:</strong> Aprovar o início da relação
                      de negócios em caso de insuficiência de informações
                      relativas ao processo de qualificação, desde que não haja
                      prejuízo aos procedimentos de monitoramento e seleção, bem
                      como aprovar a manutenção de Pessoa Exposta Politicamente
                      (PEP) e agentes inseridos em monitoramento de especial
                      atenção e, avaliação da continuidade do relacionamento
                      relacionada ao descumprimento de condições originalmente
                      pactuadas identificadas no processo de monitoramento.
                      Responsável pela elaboração, conformidade, guarda,
                      divulgação, controle de atualizações, adesão e promoção da
                      cultura organizacional de prevenção à lavagem de dinheiro
                      e financiamento ao terrorismo; gerenciamento do sistema de
                      normativo interno relacionado à Compliance e Controles
                      Internos tais como: avaliação interna de risco (AIR),
                      políticas, procedimentos, diretrizes, normas e manuais,
                      instruções normativas e recomendações institucionais para
                      conformidade do negócio; aderência da instituição ao
                      arcabouço legal, à regulamentação infralegal, às
                      recomendações dos órgãos de supervisão e aos códigos de
                      ética e de conduta, bem como prestar suporte a Diretoria
                      Executiva em assuntos relativos à Governança, Risco e
                      Compliance – GRC; atender as demandas e eventuais
                      auditorias de órgãos reguladores; análise de situações
                      reportados pela comunidade interna e canal de denúncia e,
                      realizar os procedimentos relacionados a indisponibilidade
                      de ativos estabelecida nas Resoluções do CSNU como:
                      determinação a execução de bloqueio dos ativos
                      (indisponibilidade para transferir, converter, trasladar,
                      disponibilizar os ativos ou deles dispor) de titularidade
                      de cliente pessoa física ou jurídica identificada como
                      sancionada pelo Conselho de Segurança das Nações Unidas e,
                      comunicação ao Banco Central do Brasil, ao Ministério da
                      Justiça e Segurança Pública e ao Conselho de Controle de
                      Atividade Financeira (COAF) dessa indisponibilidade de
                      ativos ou das tentativas de sua transferência, conforme
                      disposto no artigo 4º da Resolução BCB nº 44<sup>3</sup>,
                      de 24 de novembro de 2020.
                    </li>
                    <li>
                      <strong>PLD-FTP:</strong> Responsável pelo monitoramento
                      tempestivo de clientes e operações, mediante a avaliação
                      da capacidade econômico financeira visando coerência nos
                      negócios realizados e indícios de lavagem de dinheiro e
                      financiamento ao terrorismo; implementar processos e
                      procedimentos para identificação, monitoramento e análise
                      de comportamentos, operações e/ou transações suspeitas de
                      lavagem de dinheiro e financiamento ao terrorismo;
                      reportar ao Diretor de Compliance ou Comitê Executivo
                      comportamentos, operações e/ou transações suspeitas para
                      deliberação; reportar ao COAF/UIF, após a devida
                      deliberação, suspeitas de lavagem de dinheiro e
                      financiamento ao terrorismo; realizar diligências sempre
                      que necessário em clientes, fornecedores e/ou parceiros;
                      inclusão de clientes em monitoramento de especial atenção.
                    </li>
                    <li>
                      <strong>
                        ONBOARDING & BACKGROUND CHECK (CADASTRO):{' '}
                      </strong>
                      Cumprir as normas internas e regulamentos vigentes quanto
                      à Avaliação Interna de Riscos (AIR) relacionado aos
                      processos de Política de Responsabilidade Socioambiental
                      (“<u>PRSAC</u>”); definir procedimentos para
                      identificação, qualificação e obtenção de dados cadastrais
                      visando a identificação e conhecimento do cliente bem como
                      garantir o atendimento regulatório; definir controles para
                      validação dos dados cadastrais declarados pelos clientes;
                      Garantir que o cadastro do cliente esteja em conformidade
                      com o status da Receita Federal; Identificar o
                      Beneficiário Final dos clientes Pessoa Jurídica e,
                      identificar clientes Pessoas Expostas Politicamente (PEP),
                      clientes em listas restritivas, clientes que residem em
                      região de fronteira ou possuem nacionalidade em país
                      considerado sensível para fins de PLD-FT, ou que
                      apresentam enquadramento em profissão de risco ou
                      atividades de risco, que seguem procedimento particular
                      com a efetivação do processo apenas após autorização
                      explicita do Diretor de Compliance.
                    </li>
                    <li>
                      <strong>TECNOLOGIA DA INFORMAÇÃO (T.I.):</strong> Garantir
                      que os sistemas adotados por esta <strong>OM DTVM</strong>{' '}
                      estejam adequadamente em funcionamento, garantindo a
                      resolução de eventuais falhas no menor tempo de resposta
                      possível.
                    </li>
                    <li>
                      <strong>AUDITORIA INTERNA:</strong> Responsável por
                      avaliar e propor medidas para aprimoramento e efetividade
                      da Política de Responsabilidade Socioambiental (“
                      <u>PRSAC</u>”) e processos que a <strong>OM DTVM</strong>{' '}
                      estabelece, sempre verificando a aderência às
                      regulamentações em vigor e aplicabilidade fática
                      <sup>4</sup>.
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
            <span
              style={{
                fontSize: '0.8em',
                textAlign: 'left',
                marginTop: '20px',
              }}
            >
              <p>
                <sup>3</sup>Estabelece procedimentos para a execução pelas
                instituições autorizadas a funcionar pelo Banco Central do
                Brasil das medidas determinadas pela Lei nº 13.810, de 8 de
                março de 2019, que dispõe sobre o cumprimento de sanções
                impostas por resoluções do Conselho de Segurança das Nações
                Unidas, incluída a indisponibilidade de ativos de pessoas
                naturais e jurídicas e de entidades, e a designação nacional de
                pessoas investigadas ou acusadas de terrorismo, de seu
                financiamento ou de atos a ele correlacionados.
              </p>
              <p>
                <sup>4</sup>Além da Auditoria Interna, a cada 03 (três) anos, a{' '}
                <strong>OM DTVM</strong> realiza o processo de renovação do
                Certificado ISO 14001 que auxilia na identificação e gestão dos
                riscos ambientais associados aos processos internos da atividade
                desenvolvida pela organização, o que possibilita uma avaliação
                independente dos níveis de proteção do meio ambiente.
              </p>
            </span>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DIRETRIZES DE GERENCIAMENTO E CONTROLE DE RISCOS SOCIOAMBIENTAIS
                E CLIMÁTICOS
              </li>
              <p>
                Em consonância ao princípio da relevância, por sua vez, esta{' '}
                <strong>OM DTVM</strong> reconhece a sua exposição aos riscos
                socioambientais inerentes ao seu ramo de operação e, em razão
                disso, condicionou os seus sistemas, rotinas e procedimentos,
                para identificar, classificar, monitorar, controlar e mitigar
                sua ocorrência e decorrências.
              </p>
              <li className="list-unstyled">
                <p>
                  O controle de tais riscos, portanto, é engendrado através de
                  múltiplas estruturas e protocolos desenvolvidos por esta{' '}
                  <strong>OM DTVM</strong>, os quais serão individual e
                  sucessivamente explorados nos subtópicos posteriores.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                AQUISIÇÃO DE OURO BRUTO
              </li>
              <p>
                Começar-se-á a exposição e análise dos protocolos estabelecidos
                por esta <strong>OM DTVM</strong> pela aquisição de ouro bruto,
                a qual representa o maior grau de exposição desta{' '}
                <strong>OM DTVM</strong> à riscos socioambientais, pois, como
                mencionado alhures, constitui-se como seu maior ramo de operação
                <sup>5</sup>.{' '}
              </p>
              <li className="list-unstyled">
                <p>
                  Em razão disso, a <strong>OM DTVM</strong> utiliza desta
                  Política de Riscos Socioambientais como um meio de estabelecer
                  protocolos para o fim de coibir sua associação com quaisquer
                  atividades que excedam os limites legais e regulatórios de tal
                  ramo de sua operação.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Nesse diapasão, a{' '}
                  <strong>
                    <u>primeira etapa</u>
                  </strong>{' '}
                  do processo de aquisição de ouro bruto é constituído pela
                  avaliação prévia dos potenciais impactos socioambientais
                  negativos.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Tal avaliação é de responsabilidade da estrutura de Compliance
                  da <strong>OM DTVM</strong>, a qual elaborou uma Política de
                  Aquisição de Ouro bruto, cuja etapa inicial é constituída pela
                  conferência prévia da documentação apresentada pelo Vendedor
                  do Ouro<sup>6</sup> perante um dos Postos de Compra de Ouro (“
                  <u>PCO</u>
                  ”) vinculados à Instituição Financeira, “validação” das
                  informações e, posteriormente, a “análise reputacional” deste.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  A “validação” das informações disponibilizadas (dados
                  cadastrais de preenchimento obrigatório) é realizada pela{' '}
                  <strong>OM DTVM</strong> via sistema ouro 4GL, o qual utiliza
                  a API (Application Programming Interface), que é integrada ao
                  sistema PH3A, para verificar a autenticidade das informações
                  apresentadas<sup>7</sup>.
                </p>
                <p>
                  Na{' '}
                  <strong>
                    <u>segunda etapa</u>
                  </strong>
                  , ocorre a “análise reputacional (“
                  <u>KYC</u>”)” do Vendedor do Ouro, em que se utiliza o sistema
                  “RISC” da empresa Advice Compliance Solutions, para o fim de
                  pesquisa de dados reputacionais (“<u>Dossiê</u>”) de pessoas
                  físicas (através do CPF e nome completo) e/ou jurídicas
                  (através do CNPJ e razão social).
                </p>
                <p>
                  Tal sistema é capaz de realizar uma correlação entre os dados
                  apresentados e os registros das Listas Restritivas Nacionais e
                  Internacionais<sup>8</sup>, bem como de consultar os órgãos
                  IBAMA (Instituto Brasileiro do Meio Ambiente e dos Recursos
                  Naturais Renováveis) e SEMA (Secretaria de Estado de Meio
                  Ambiente), para a constatação de eventual ocorrência de
                  embargos e/ou sanções ambientais<sup>9</sup>, e a depender
                  avaliação, também serão disponibilizados extratos de eventuais
                  processos judiciais e/ou administrativos<sup>10</sup>.
                </p>
                <p>
                  Na{' '}
                  <strong>
                    <u>terceira etapa</u>
                  </strong>
                  , a <strong>OM DTVM </strong>realiza um estudo acerca das
                  condições do trabalho desenvolvido pelo Vendedor, o qual
                  pauta-se pela análise de existência de processos judiciais
                  e/ou administrativos em que o Vendedor eventualmente seja Réu.
                  O estudo objetiva verificar a ocorrência de quaisquer
                  preconceitos no ambiente de labor, bem como se este está sendo
                  viabilizado em condições análogas à escravidão ou através de
                  trabalho infantil. Além disso, apura-se a prática de tráfico
                  de pessoas e/ou exploração sexual.
                </p>
                <p>
                  Na{' '}
                  <strong>
                    <u>quarta etapa</u>
                  </strong>
                  , a <strong>OM DTVM</strong> realiza uma análise via satélite
                  através dos sistemas SIGMINE<sup>11</sup> e SCCON<sup>12</sup>{' '}
                  Geospatial (solução de mapeamento e escala via satélite) para
                  identificação do indício de extração, bem como consulta o
                  website “Jazidas”<sup>13</sup>, para o fim de conferência do
                  recolhimento de CFEM (Compensação Financeira pela Exploração
                  Mineral), através do qual pode aferir o valor aproximado de
                  produção anual da lavra garimpeira<sup>14</sup>.
                </p>
                <p>
                  Na{' '}
                  <strong>
                    <u>quinta etapa</u>
                  </strong>
                  , a <strong>OM DTVM</strong>, através de “Contratos de
                  Prestação de Serviços” com Geólogos Ambientais, elabora um
                  “Relatório de Conferência Presencial de Requisitos para
                  Avaliação Ambiental das Áreas Exploradas”, cujo escopo é
                  assegurar a veracidade das informações fornecidas pelo
                  Vendedor com relação à área declarada como de exploração.
                </p>
                <p>
                  Neste relatório, os especialistas ambientais se deslocam às
                  áreas de extração de ouro, a fim de apurar se a exploração
                  está sendo realizada dentro dos limites estabelecidos pela Lei
                  Federal nº 7.805, de 18 de julho de 1989, Lei Complementar nº
                  38/95, Lei Federal nº. 9.605/1998, Lei Complementar nº 232, de
                  21 de dezembro de 2005, Decreto Federal nº 6514/08, Lei
                  Complementar nº 140/11, Portaria ANM nº 155, de 12 de maio de
                  2016, Lei Complementar nº 592, de 26 de maio de 2017, Decreto
                  Estadual nº 697/20 e Resolução ANM nº 95/22, e, assim, não
                  invadindo as áreas de proteção ambiental e/ou de reservas
                  indígenas.
                </p>
                <p>
                  Tão somente com as cinco etapas integralmente completas e
                  desprovidas de sinalizações negativas<sup>15</sup>, a{' '}
                  <strong>OM DTVM</strong> prossegue com o procedimento de
                  aquisição do ouro bruto, uma vez que assegurada a origem
                  legalizada deste.
                </p>
              </li>
            </ul>
            <span
              style={{
                fontSize: '0.8em',
                textAlign: 'left',
                marginTop: '20px',
              }}
            >
              <p>
                <sup>5</sup>Tal atuação é constantemente exposta à riscos
                socioambientais, como a aquisição de ouro advindo de garimpo
                ilegal ou utilização dos recursos dispendidos pela Instituição
                Financeira para o fim de financiamento de atividades ilegais.
              </p>
              <p>
                <sup>6</sup>&nbsp;Para aquisição do Ouro Bruto, a{' '}
                <strong>OM DTVM</strong> determina a apresentação de vasta base
                documental, abaixo detalhada: <u>(Pessoa Física)</u>: Documento
                de identificação do vendedor, Carteira de Habilitação (CNH),
                Registro Geral (RG) ou Registro no Conselho de Classe; Carteira
                de Garimpeiro; Permissões de Lavras Garimpeiras (PLG);
                Questionário de Aquisição de Ouro Bruto promovido pela{' '}
                <strong>OM DTVM</strong>; Licença Operacional (L.O.) da lavra
                utilizada para extração de ouro bruto, “Contrato de Parceria”
                entre o titular da PLG e Garimpeiro que deverá estar assinado
                por ambas as partes, registrado em cartório e anexado pelo
                detentor da lavra junto a Agência Nacional de Mineração.{' '}
                <u>(Pessoa Jurídica)</u>: Ato Constitutivo da Empresa; Documento
                dos Representantes (como Carteira de Habilitação (CNH) e
                Registro Geral (RG) ou Registro no Conselho de Classe,
                procurador e/ou beneficiário final); Permissões de Lavras
                Garimpeiras (PLG); Questionário de Aquisição de Ouro Bruto
                promovido pela <strong>OM DTVM</strong>; Licença Operacional
                (L.O.) da lavra utilizada para extração de ouro bruto, “Contrato
                de Parceria” entre o titular da PLG e Garimpeiro que deverá
                estar assinado por ambas as partes, registrado em cartório e
                anexado pelo detentor da lavra junto a Agência Nacional de
                Mineração.
              </p>
              <p>
                <sup>7</sup>A autenticação dos dados apresentados é feita
                através da confrontação destes com os dados registrados perante
                a Receita Federal do Brasil e demais sistemas aplicáveis, como a
                consulta da autenticação da PLG e de seu titular perante o
                website da Agência Nacional de Mineração.
              </p>
              <p>
                <sup>8</sup>A <strong>OM DTVM</strong> também disponibiliza a
                consulta manual de tais dados através da utilização do sistema
                E-GUARDIAN, para fins de PLDFT (Prevenção a Lavagem de Dinheiro
                e Financiamento ao Terrorismo).{' '}
              </p>
              <p>
                <sup>9</sup>O sistema também é capaz de identificar o status de
                tramitação (imposição de sanção, recursos ou trânsito em
                julgado).{' '}
              </p>
              <p>
                <sup>10</sup> Processos judiciais em tramitação perante os
                Tribunais <strong>(i)</strong> Regional Federal,{' '}
                <strong>(ii)</strong> de Contas da União, <strong>(iii)</strong>
                de Justiça Estadual, <strong>(iv)</strong> Superior Tribunal de
                Justiça, <strong>(v)</strong>
                Supremo Tribunal Federal e <strong>(vi)</strong> Justiça
                Federal, bem como processos administrativos perante o{' '}
                <strong>(vii) </strong> Conselho Nacional de Justiça e{' '}
                <strong>(viii)</strong> Ministério Público Federal.
              </p>
              <p>
                <sup>11</sup> Sistema de Informações Geográficas da Mineração,
                desenvolvido pela Agência Nacional de Mineração e disponível em:{' '}
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={
                    'https://geo.anm.gov.br/portal/apps/webappviewer/index.html?id=6a8f5ccc4b6a4c2bba79759aa952d908'
                  }
                  target="_blank"
                >
                  https://geo.anm.gov.br/portal/apps/webappviewer/index.html?id=6a8f5ccc4b6a4c2bba79759aa952d908.
                </a>
              </p>
              <p>
                <sup>12</sup> Através do sistema SCCON, a OM DTVM verifica a
                área declarada de extração, viabilizando seu monitoramento
                contínuo através da detecção e quantificação das mudanças nesta.{' '}
              </p>
              <p>
                <sup>13</sup> Disponível em:{' '}
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={'https://www.jazida.com/'}
                  target="_blank"
                >
                  https://www.jazida.com/
                </a>{' '}
                .{' '}
              </p>
              <p>
                <sup>14</sup> A <strong>OM DTVM</strong> confronta o valor
                aferido da produção anual com a quantidade de ouro ofertada,
                para o fim de atestar sua viabilidade técnica e fática, de modo
                a afastar eventuais falsas declarações de área de extração.{' '}
              </p>
              <p>
                <sup>15</sup> A <strong>OM DTVM</strong> desenvolveu uma
                parametrização de riscos socioambientais, a qual diretamente
                influirá na viabilidade negocial, como explorar-se-á no tópico
                7.{' '}
              </p>
            </span>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                PARAMETRIZAÇÃO DE NEGOCIAÇÃO – PRESERVAÇÃO DA SEGURANÇA
                SOCIOAMBIENTAL
              </li>
              <li className="list-unstyled">
                <p>
                  Esta <strong>OM DTVM</strong>,com o escopo de viabilizar o
                  desenvolvimento de sua operação comercial em respeito à
                  necessária preservação da segurança socioambiental,
                  estabeleceu parâmetros destinados a vedar quaisquer
                  negociações com Vendedores cuja análise sistêmica apresente ao
                  menos uma das situações abaixo listadas:
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li className="list-unstyled">
                      a) Desenvolvimento do trabalho em condições análogas à
                      escravidão ou qualquer outra forma de desrespeito aos
                      direitos humanos.
                    </li>
                    <li className="list-unstyled">
                      b) Operação da qual poderá decorrer o financiamento, apoio
                      ou incentivo de qualquer espécie de grupo armado, público
                      ou privado;
                    </li>
                    <li className="list-unstyled">
                      c) Operação da qual poderá decorrer qualquer ação que,
                      direta ou indiretamente se relacione à lavagem de dinheiro
                      ou financiamento ao terrorismo;
                    </li>
                    <li className="list-unstyled">
                      d) Operação da qual poderá decorrer violação de quaisquer
                      das exigências legais e regulamentares que normatizam a
                      nossa atividade;
                    </li>
                    <li className="list-unstyled">
                      e) Vendedor com PLG e/ou LO inativas;
                    </li>
                    <li className="list-unstyled">
                      f) Área declarada pelo Vendedor sem indícios de extração;
                    </li>
                    <li className="list-unstyled">
                      g) Área declarada esteja embargada pela AMN.
                    </li>
                    <li className="list-unstyled">
                      h) Área declarada esteja abarcada pela proteção indígena
                      e/ou ambiental.
                    </li>
                    <li className="list-unstyled">
                      i) Multas ambientais reiteradas impostas pelo IBAMA, em
                      razão dos mesmos fatos geradores.
                    </li>
                  </ul>
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Mediante a adoção de tais restrições, a{' '}
                  <strong>OM DTVM</strong> viabiliza o desenvolvimento de sua
                  atividade comercial de forma sustentável, uma vez que seu
                  escopo é coibir sua participação em qualquer operação que
                  apresente desconformidade com a legislação e regulamentos do
                  Banco Central do Brasil.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                APLICAÇÃO DAS PARAMETRIZAÇÕES NEGOCIAIS COMO UM MEIO DE ATUAÇÃO
                CONSONANTE À PRSAC
              </li>
              <li className="list-unstyled">
                <p>
                  Vê-se que, por meio da parametrização apresentada, a{' '}
                  <strong>OM DTVM</strong> promove o desenvolvimento de sua
                  atuação negocial em estrita consonância às diretrizes
                  apresentadas no teor da Resolução CMN nº 4.945/21, uma vez que
                  objetiva e cumulativamente promove:
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li className="list-unstyled">
                      <u>Prevenção de Risco Ambiental e Climático: </u>
                      <p>
                        - Por meio das “quarta” e “quinta” etapas de seu
                        processo de aquisição de ouro ativo financeiro, a{' '}
                        <strong>OM DTVM</strong>
                        assegura a legalidade de sua aquisição, tendo em vista a
                        utilização de sistemas avançados e visitação in loco dos
                        garimpos, para o específico fim de certificação pela ES
                        da não aquisição de ouro com índicos de extração ilegal,{' '}
                        <strong>
                          o que engendra maior prevenção de risco ambiental e
                          climático em sua atuação.
                        </strong>
                      </p>
                    </li>
                    <li className="list-unstyled">
                      <u>Prevenção de Risco Social:</u>
                      <p>
                        - Consonante “Tópico 07” adiante e{' '}
                        <u>“terceira etapa”</u>, a<strong>OM DTVM</strong>{' '}
                        aborda em múltiplos aspectos o risco social em sua
                        atuação, seja através da constatação das condições
                        humanas nos estabelecimentos de labor de seus parceiros
                        negociais ou na promoção de eventos destinados a
                        participação social em atividades de lazer e
                        conhecimento intelectual.
                      </p>
                      <p>
                        - Além disso, a <strong>OM DTVM</strong>, por meio de
                        seus sistemas internos, logra êxito em índicos de
                        envolvimento em crimes humanitários por aquela potencial
                        parte negocial, o que é um fator para imediato declínio
                        da potencial operação.{' '}
                      </p>
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                PROJETOS VIABILIZADOS E APOIADOS PELA OM DTVM
              </li>
              <li className="list-unstyled">
                <p>
                  Esta <strong>OM DTVM</strong>, com o escopo de viabilizar o
                  desenvolvimento de sua operação em respeito aos direitos
                  humanos e enfocada em sustentabilidade, viabiliza e apoia
                  diversos projetos, os quais serão declinados nos subtópicos
                  posteriores.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                PROJETOS AMBIENTAIS E CLIMÁTICOS
              </li>
              <li className="list-unstyled">
                <p>
                  Como bem disponibilizado em seu{' '}
                  <a
                    rel="noreferrer"
                    className="text-decoration-none"
                    href={'https://www.ourominas.com/om/planta-arvore'}
                    target="_blank"
                  >
                    website
                  </a>
                  <sup>16</sup>, esta <strong>OM DTVM</strong> desenvolve e
                  apoia projetos ambientais relevantes, destinados a
                  operacionalização de sua atividade comercial de forma
                  sustentável:
                </p>
              </li>
              <p>PROGRAMA PLANTE ÁRVORE:</p>
              <li className="list-unstyled">
                <p>
                  O cliente que efetua a compra do “Cartão OuroFácil Plante
                  Árvore” ajuda esta <strong>OM DTVM</strong> a manter o
                  Programa Plante Árvore Ativo.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  O Programa Plante Árvore é uma ação do Instituto Brasileiro de
                  Floresta (IBF), voltada à restauração florestal de mata nativa
                  em áreas degradadas, dentro dos limites do bioma Mata
                  Atlântica e Cerrado.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  O programa consiste no cadastro de proprietários rurais que
                  tenham em suas propriedades, áreas a serem restauradas ou
                  áreas de mata ciliar que devem ser refeitas.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  O IBF se encarrega da coleta das sementes, cultivo das mudas e
                  escolha da área para o plantio, e ainda se compromete em
                  oferecer toda a orientação necessária para o preparo do solo,
                  plantio e manutenção das árvores durante o período de dois
                  anos.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  O restauro florestal é projetado levando-se em conta critérios
                  de máxima diversidade de espécies e respeitando as
                  características do ecossistema local. Sempre é plantado um mix
                  de diferentes espécies nativas, respeitando critérios da
                  dinâmica de sucessão ecológica e condições específicas do
                  local escolhido, visando restaurar a vegetação nativa da área
                  ao mais próximo possível de sua condição original.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Assim sendo, a OM torna-se responsável pelo plantio de uma
                  floresta, com manutenção regular até seu pleno
                  desenvolvimento.
                </p>
              </li>

              <p>OBJETIVOS:</p>

              <li className="list-unstyled">
                <p>
                  * Recuperar trechos de mata degradados através do plantio de
                  espécies florestais nativas em áreas de preservação permanente
                  (APP), reservas legais (RL) e fragmentos de matas;
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  * Maximizar a biodiversidade das áreas restauradas por meio da
                  criação de corredores ecológicos ao longo da floresta;
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  * Melhorar a capacidade hídrica dos rios e nascentes,
                  controlando a erosão do solo e reduzindo o assoreamento dos
                  rios;
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  * Auxiliar produtores rurais no restauro florestal de suas
                  áreas, contribuindo para a regularização da propriedade junto
                  ao órgão licenciador estadual e a averbação junto ao registro
                  de imóveis competente;
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  * Auxiliar pessoas físicas e jurídicas na realização de
                  projetos voluntários de reflorestamento, voltados também na
                  compensação de emissões de gases de efeito estufa;
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  * Certificar essas ações através da concessão do Selo e
                  Certificado da Plante Árvore e IBF, atribuindo uma imagem
                  positiva à esta <strong>OM DTVM</strong>.
                </p>
              </li>
            </ul>
            <span
              style={{
                fontSize: '0.8em',
                textAlign: 'left',
                marginTop: '20px',
              }}
            >
              <p>
                <sup>16</sup> Disponível em:{' '}
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={'https://www.ourominas.com/om/planta-arvore'}
                  target="_blank"
                >
                  https://www.ourominas.com/om/planta-arvore.
                </a>
              </p>
            </span>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                PROJETOS SOCIAIS
              </li>
              <p>
                Como bem disponibilizado em seu{' '}
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={'https://www.ourominas.com/om/projetos-sociais'}
                  target="_blank"
                >
                  website
                </a>
                <sup>17</sup>, esta <strong>OM DTVM</strong> desenvolve e apoia
                projetos sociais relevantes, destinados a operacionalização de
                sua atividade comercial em consonância ao atendimento dos
                direitos humanos:
              </p>
              <hr />
              <p>PROJETOS DE SAÚDE:</p>
              <li className="list-unstyled">
                <p>
                  Voltado ao atendimento gratuito e de alta qualidade em várias
                  frentes da medicina no país.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Através do Pronon, é possível viabilizar pesquisas, novos
                  equipamentos, custeio de hospitais, novos procedimentos
                  médicos e o tratamento oncológico.
                </p>
              </li>
              <li className="list-unstyled">
                <p>Projeto de Saúde: Hospital Ana Nery -2021.</p>
              </li>
              <hr />
              <p>PROJETOS DE INCLUSÃO:</p>
              <li className="list-unstyled">
                <p>
                  Pronas (Programa Nacional de Apoio à Atenção da Saúde da
                  Pessoa com Deficiência).
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Também chamada de Lei da Mobilidade Física, é a primeira lei
                  criada para construir e manter projetos que possibilitam a
                  assistência, prevenção de doenças, tratamentos e a recuperação
                  de pessoas com câncer ou com necessidades especiais.
                </p>
              </li>
              <li className="list-unstyled">
                <p>Projeto Inclusão: APADV Paralímpico – 2021. </p>
              </li>
              <hr />
              <p>PROJETOS DE IDOSO:</p>
              <li className="list-unstyled">
                <p>Fundo Municipal do Idoso</p>
              </li>
              <li className="list-unstyled">
                <p>
                  Tem o objetivo de assegurar, através de projetos sociais, os
                  direitos de pessoas carentes com 60 anos ou mais, garantindo
                  saúde, assistência, inclusão social e lazer, proporcionando
                  qualidade de vida aos idosos, ou seja, criar condições para
                  promover a autonomia e bem-estar.
                </p>
              </li>
              <li className="list-unstyled">
                <p>Amparo ao Idoso = HCB – 2022.</p>
              </li>
              <li className="list-unstyled">
                <p>
                  Ônibus Rosa envelhecendo saudável e saúde da mulher – 2022.
                </p>
              </li>
              <hr />
              <p>PROJETOS DE CRIANÇA:</p>
              <li className="list-unstyled">
                <p>Fundo Municipal dos Direitos da Criança e do Adolescente.</p>
              </li>
              <li className="list-unstyled">
                <p>
                  É um instrumento essencial para a população infanto-juvenil.
                  Projetos destinados para esse público são capazes de dar um
                  lar, formar, educar, dar saúde e lazer desde os primeiros anos
                  de vida.
                </p>
              </li>
              <li className="list-unstyled">
                <p>Fome Não Espera – 2021.</p>
              </li>
              <li className="list-unstyled">
                <p>Cuidar HOB - Hospital de Amor – 2022.</p>
              </li>
              <hr />
              <p>PROJETOS DE ESPORTE:</p>
              <li className="list-unstyled">
                <p>
                  Desde o incentivo à cultura da prática de esporte, como
                  reforço na saúde, até a inserção a nível profissional, dando
                  oportunidade para futuros atletas, inclusive olímpicos.
                </p>
              </li>
              <li className="list-unstyled">
                <p>Clínica de Taekwondo nas escolas públicas – 2020 e 2021.</p>
              </li>
              <li className="list-unstyled">
                <p>Projeto Brasileirinhos – 2022.</p>
              </li>
              <li className="list-unstyled">
                <p>Projeto Brasileirinhos – 2023.</p>
              </li>
              <hr />
              <p>PROJETOS DE CULTURA:</p>
              <li className="list-unstyled">
                <p>
                  PRONAC ROUANET - Com o objetivo de ampliar o acesso à cultura,
                  o Pronac (Programa Nacional de Apoio à Cultura) - instituído
                  pela Lei nº 8.313/91 e regulamentado pelo Decreto n° 5.761/06
                  - apoia, valoriza e difunde as manifestações culturais
                  brasileiras. Ele estimula a produção cultural, o que o torna
                  um gerador de renda, emprego e desenvolvimento.
                </p>
              </li>
              <li className="list-unstyled">
                <p>Projeto Elas Brilham - Doc. Musical – 2022.</p>
              </li>
              <hr />
              <p>PROJETOS DE TEA (TRANSTORNO DO ESPECTRO AUTISTA):</p>
              <li className="list-unstyled">
                <p>
                  Estimular o convívio social, desenvolvimento de
                  potencialidades e ampliação de qualidade de vida para as
                  pessoas com TEA (transtorno do espectro autista) e seus
                  familiares.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  FADA 2024 - Fundação de Apoio e Desenvolvimento do Autista
                </p>
              </li>
            </ul>
            <span
              style={{
                fontSize: '0.8em',
                textAlign: 'left',
                marginTop: '20px',
              }}
            >
              <p>
                <sup>16</sup> Disponível em:{' '}
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={'https://www.ourominas.com/om/projetos-sociais'}
                  target="_blank"
                >
                  https://www.ourominas.com/om/projetos-sociais.
                </a>
              </p>
            </span>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                GUARDA E RECUPERAÇÃO DE DOCUMENTOS
              </li>
              <li className="list-unstyled">
                <p>
                  A <strong>OM DTVM</strong> garante o sigilo de todas as
                  informações e de todos os documentos relacionados com suas
                  operações, inclusive àqueles relacionadas ao risco
                  socioambiental.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  A <strong>OM DTVM</strong> igualmente garante a guarda e a
                  recuperação de todos os documentos e informações de suas
                  operações (operacionais e administrativas), inclusive as
                  informações e documentos referentes às perdas decorrentes de
                  danos socioambientais, por 10 (dez) anos para os documentos
                  referentes às operações de transferência de recursos e 05
                  (cinco) anos para os demais documentos e informações.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                SANÇÕES ADMINISTRATIVAS
              </li>
              <li className="list-unstyled">
                <p>
                  A <strong>OM DTVM</strong>, com o escopo de preservar a
                  legalidade e assertividade de suas operações, desenvolveu um
                  protocolo de sanções e penalidades a serem aplicadas em face
                  de qualquer participante de sua operação, em caso de
                  descumprimento das regulações do Banco Central do Brasil ou de
                  qualquer outra legislação aplicável.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Nessa toada, no evento de alguma irregularidade, ao tomar
                  ciência, a <strong>OM DTVM</strong> instaurará um procedimento
                  interno para apuração, do que eventualmente decorrerá a
                  constatação de ocorrência e gravidade da conduta.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Verificada a ocorrência e apurada a gravidade, a{' '}
                  <strong>OM DTVM</strong>
                  aplicará as sanções proporcionalmente, as quais são
                  constituídas por advertência por ofício, advertência formal,
                  suspensão temporária do contrato ou rescisão contratual
                  motivada, sem prejuízo da aplicação de multas contratuais por
                  tal razão, e a formalização de denúncia ao Conselho de
                  Controle de Atividades Financeiras (“<u>COAF</u>”).
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                CONCLUSÃO
              </li>
              <li className="list-unstyled">
                <p>
                  Em arremate, a Política de Responsabilidade Socioambiental
                  apresentada, tem por escopo viabilizar a operação desta{' '}
                  <strong>OM DTVM</strong> em plena e irrestrita consonância aos
                  princípios basilares de sustentabilidade ambiental e social,
                  uma vez que são tomadas extensas medidas preventivas e
                  repressivas com o intuito de vedar quaisquer ações que
                  representem qualquer grau de ilegalidade.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Em assim sendo, a <strong>OM DTVM</strong> reafirma seu
                  irrestrito compromisso com o desenvolvimento sustentável de
                  suas operações, promovendo, sob tal égide, as adequações
                  necessárias.
                </p>
              </li>
            </ul>
          </p>
        </section>
      </section>
    </article>
  );
}
