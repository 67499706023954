import React from 'react';
import './credibilidade.css';
import '../../pages.css';
import img from '../../../assets/newImages/credibilidade.png';
import linha from '../../../assets/linha.png';

export default function Credibilidade() {
  return (
    <article className="art-s mb-5 p-text" id="credibilidade">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect ">
            <p className="fw-bolder text-decoration-underline">CREDIBILIDADE</p>
            <p>
              Toda autorização, certificação e associação são de grande
              importância para as empresas e são fundamentais para melhorar seus
              processos, produtos, serviços e ganhar destaque em cenário
              nacional e internacional, por isso a preocupação da Ourominas (OM)
              em estar sempre associada com os melhores do Brasil e do mundo
              mostrando sua transparência e honestidade.
            </p>
            <p>
              O valor agregado de uma autorização, certificação ou associação
              para a OM vai muito além do financeiro, já que envolve também
              todos seus colaboradores, auxiliando no desenvolvimento pessoal, e
              visa colocar as coisas em seus devidos lugares de maneira
              sistêmica, o que ajuda a empresa a entender melhor o que se passa
              internamente, melhorando o atendimento aos seus clientes e
              processos.
            </p>
            <p>Conheça abaixo todas as certificações da OM.</p>

            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>
        <hr className="mt-5"></hr>
        <h5 className="fw-bolder mt-4">BACEN</h5>
        <p>A OM é autorizada no Banco Central do Brasil (Bacen: 27930).</p>
        <p>
          O Banco Central do Brasil (BC) é o responsável pelo controle da
          inflação no país. Ele atua para regular a quantidade de moeda na
          economia que permita a estabilidade de preços. Suas atividades também
          incluem a preocupação com a estabilidade financeira. Para isso, o BC
          regula e supervisiona as instituições financeiras.
        </p>
        <hr className="mt-3"></hr>
        <h5 className="fw-bolder mt-4">CVM</h5>
        <p>A OM é credenciada junto a Comissão de Valores Mobiliários (CVM).</p>
        <p>
          A CVM é uma autarquia vinculada ao Ministério da Economia do Brasil,
          tem por objetivo regulamentar, disciplinar, normalizar e fiscalizar a
          atuação dos diversos integrantes do mercado brasileiro de valores
          mobiliários e informar ao público sobre quais as companhias que
          emitiram esses valores.
        </p>
        <hr className="mt-3"></hr>

        <h5 className="fw-bolder mt-4">ABRACAM</h5>
        <p>
          A OM é associada à entidade Associação Brasileira de Câmbio (ABRACAM).
        </p>
        <p>
          A OM se associou à ABRACAM em 2009 com o intuito de fazer parte dos
          trabalhos da maior organização representativa do segmento de câmbio
          nacional. Todos as instituições financeiras associadas à ABRACAM são
          autorizadas a operar pelo Banco Central do Brasil.
        </p>
        <p>
          A ABRACAM representa bancos de câmbio, corretoras de câmbio,
          corretoras e distribuidoras de títulos e valores mobiliários e seus
          correspondentes cambiais, que atuam em todos os estados do País e
          prestam serviço fundamental no atendimento a empresas em suas remessas
          internacionais, liquidações de exportação e importação de bens e
          serviços e compras de moeda estrangeira para o câmbio turismo.
        </p>
        <p>
          A associação trabalha para melhorar o ambiente de negócios através de
          uma interlocução ativa e frequente com as autoridades econômicas,
          reguladoras e fiscalizadoras, em prol do desenvolvimento econômico e
          social do País.
        </p>
        <hr className="mt-3"></hr>

        <h5 className="fw-bolder mt-4">ABT1 / ABT2</h5>
        <p>
          A OM possui parte de seus colaboradores e parceiros certificados na
          ABT1 e ABT2.
        </p>
        <p>
          Certificação destinada aos profissionais que trabalham ou pretendam
          trabalhar em instituições autorizadas pelo Banco Central do Brasil
          (BCB) a operarem em câmbio e para os colaboradores de seus
          correspondentes.
        </p>
        <p>
          Mais exigente do que o ABT1, o certificado ABT2 é destinado aos
          diretores e gestores responsáveis pelas áreas finalísticas (mesa de
          câmbio, compliance, avaliação de riscos, controles internos e
          back-office) das instituições autorizadas a operar em câmbio pelo BCB.
        </p>
        <hr className="mt-3"></hr>
        <h5 className="fw-bolder mt-4">AMAGOLD</h5>
        <p>
          A OM possui certificado da Americas Gold Manufactures Association
          (AMAGOLD), aprovação por comercializar ouro com teor de pureza 999,
          que está em conformidade com os mais altos padrões internacionais.{' '}
        </p>
        <p>
          A AMAGOLD  foi fundada em 2001 no Brasil pelo World Gold Council
          (Conselho Mundial do Ouro), com o objetivo de implantar no País o selo
          de garantia de qualidade ao teor do ouro.
        </p>
        <p>
          Seus membros são empresas envolvidas com a comercialização de joias de
          ouro e de ouro puro, com a garantia da qualidade do mesmo. Isso
          implica em submeter-se a constantes auditorias e análises para que
          possa ter o teor do ouro certificado.
        </p>
        <p>
          Associada desde 2012, a OM faz parte desse seleto grupo de empresas
          que procuram sempre a máxima qualidade do teor do ouro que vendem.
        </p>
        <p>
          O ouro vendido pela OM tem o seu teor auditado constantemente,
          garantindo dessa forma que ele está dentro dos exigentes padrões
          internacionais de qualidade, com o teor de 999.
        </p>
        <hr className="mt-3"></hr>

        <h5 className="fw-bolder mt-4">ANBIMA: CPA-10 / CPA-20</h5>
        <p>
          A OM possui parte de seus colaboradores e parceiros certificados na
          ANBIMA CPA-10 e CPA-20.
        </p>
        <p>
          A ANBIMA é a principal certificadora de profissionais do mercado
          financeiro brasileiro no setor de distribuição de produtos de
          investimento, com mais de 2400 mil certificações emitidas.
        </p>
        <p>
          Essa certificação surgiu para garantir uma formação adequada para
          trabalhar no mercado financeiro.
        </p>
        <hr className="mt-3"></hr>
        <h5 className="fw-bolder mt-4">GPTW</h5>
        <p>
          A OM é certificada pelo Great Place to Work (GPTW) - traduzido como
          Melhores Lugares para Trabalhar.
        </p>
        <p>
          Em 2022, a OM foi a primeira Distribuidora de Títulos e Valores
          Mobiliários (DTVM) da America Latina a possuir a certificação GPTW.
        </p>
        <p>
          O Great Place to Work se tornou um programa de certificação presente
          em mais de 53 países que, por meio de pesquisas possui um mesmo
          objetivo: medir a percepção dos funcionários em relação a empresa e
          descobrir quais são as melhores empresas para se trabalhar.
        </p>
        <p>
          Para receber o selo de “Great Place to Work” as empresas precisam
          atingir a nota 70 ou mais em requisitos como: clima organizacional,
          remuneração, benefícios, autonomia, confiança, transparência na gestão
          e orgulho de fazer parte da organização.
        </p>

        <hr className="mt-3"></hr>
        <h5 className="fw-bolder mt-4">IBF</h5>
        <p>A OM é certificada pelo Instituto Brasileiro de Florestas (IBF).</p>
        <p>
          O IBF certifica empresas que realizam o plantio de árvores nativas ou
          patrocina projetos socioambientais com o Selo “Plante Árvore” que está
          em conformidade com a legislação ambiental vigente e de acordo com o
          modelo proposto pela Norma ISO 14020 para concessão de selos
          ambientais.
        </p>
        <p>
          Este Selo indica que OM investiu no plantio de árvores e sequestrou
          Gases do Efeito Estufa (GEE).
        </p>

        <hr className="mt-3"></hr>
        <h5 className="fw-bolder mt-4">PLANTE ÁRVORE</h5>
        <p>
          A OM possui o selo Plante Árvore e o título e certificado de empresa
          Amiga da Floresta pelo fomento do plantio de árvores nativas,
          responsáveis pela absorção de gases do efeito estufa (GEF) e a
          reestruturação de áreas florestal em cidades brasileiras.
        </p>

        <hr className="mt-3"></hr>
        <h5 className="fw-bolder mt-4">AWTRA</h5>
        <p>A OM recebeu o prêmio de Cultura de Compliance pela Awtra.</p>
        <p>
          A premiação aconteceu pelo fato de a OM ser uma empresa que possui uma
          cultura de compliance, ou seja, a empresa possui um conjunto de normas
          e obrigações que valorizam a ética e são parte central e inalterável
          da cultura corporativa. Além disso, são vivenciadas dentro da rotina
          da instituição.{' '}
        </p>
        <p>
          A OM possui uma forte cultura de compliance, onde a integridade está
          presente em todos os níveis da empresa.
        </p>

        <hr className="mt-3"></hr>
        <h5 className="fw-bolder mt-4">ISO</h5>
        <p>A OM implementou o Sistema de Gestão Integrado (SGI)</p>
        <p>
          A implementação do SGI é uma maneira de a OM afirmar, perante a
          clientes, órgãos fiscalizadores e comunidade, que não se preocupa
          somente com os lucros. Está atenta e adota procedimentos que preservam
          o meio ambiente e o bem-estar das pessoas. Em 2021, a OM foi a
          primeira Instituição Financeira da America Latina a possuir as
          certificações ISO 9001, ISO 14001 e ISO 45001.
        </p>
        <p>
          O SGI consiste em três certificações do International Organization for
          Standardization (ISO): Gestão da Qualidade (ISO 9001), Gestão
          Ambiental (ISO 14001) e Gestão da Segurança e Saúde Ocupacional (ISO
          45001), representam uma forma de assegurar que empresas públicas ou
          privadas estejam aptas para fornecer um produto, serviço ou sistema
          conforme as exigências das agências reguladoras e dos clientes.
        </p>
        <p>
          Entenda mais sobre as três certificações da ISO que foram implantadas
          com o objetivo de melhorar a qualidade de produtos e serviços da OM:
        </p>

        <hr className="mt-3"></hr>
        <h5 className="fw-bolder mt-4">ISO 9001</h5>
        <p>
          É uma norma que certifica os Sistemas de Gestão da Qualidade. É um
          conjunto de ações preventivas, para garantir e padronizar um serviço
          ou um produto, tem como objetivo, trazer confiança ao cliente de que
          os produtos e serviços da empresa serão criados de modo consistente, a
          fim de que adquira uma qualidade, de acordo com aquilo que foi
          definido pela empresa.
        </p>
        <p>A OM é certificado da QMS Certification na norma ISO 9001.</p>
        <hr className="mt-3"></hr>

        <h5 className="fw-bolder mt-4">ISO 14001</h5>
        <p>
          É aplicável somente em empresas de grande porte. É uma norma aceita
          internacionalmente que define os requisitos para colocar um sistema de
          gestão ambiental em vigor, ajuda a melhorar o desempenho das empresas
          por meio da utilização eficiente dos recursos e da redução da
          quantidade de resíduos, ganhando assim a confiança das partes
          interessadas.
        </p>
        <p>A OM é certificado da QMS Certification na norma ISO 14001.</p>

        <hr className="mt-3"></hr>
        <h5 className="fw-bolder mt-4">ISO 45001</h5>
        <p>
          É uma norma internacional para o Sistema de Gestão de Saúde e
          Segurança Ocupacional (SGSSO), a qual traz como foco a melhoria do
          desempenho da empresa em termos de Saúde e Segurança do Trabalho
          (SST).
        </p>
        <p>A OM é certificado da QMS Certification na norma ISO 45001.</p>

        <hr className="mt-3"></hr>
      </section>
    </article>
  );
}
